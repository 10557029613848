import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useFormikContext } from 'formik';

import { ISummaryFormSection } from '../../models/summary-form-section';
import { ILabelSummaryFormValues } from '../../models/summary-form.model';
import { useCampaignSettings } from '../../hooks/use-campaign-settings';

import FormField from '../atoms/form-field';
import FormInput from '../atoms/form-input';
import FormDatePicker from '../hoc/form-date-picker';

const SummaryFormDataSection: React.FC<ISummaryFormSection> = ({
    wrapperClassName,
    gridClassName,
    labelClassName,
    fieldClassName,
    smallFieldClassName = '',
    initDate = null,
    occasion
}) => {
    const { t } = useI18next();
    const { errors, touched } = useFormikContext<ILabelSummaryFormValues>();

    const { minDate } = useCampaignSettings();

    let eventLabelText;

    switch (occasion) {
        case 'wedding':
            eventLabelText = t('label.summary.form.wedding.date.label');
            break;
        case 'birthday':
            eventLabelText = t('label.summary.form.birthday.date.label');
            break;
        case 'anniversary':
            eventLabelText = t('label.summary.form.anniversary.date.label');
    }

    return (
        <div className={wrapperClassName}>
            <span className={labelClassName}>{t('label.summary.form.data.section')}</span>
            <div className={gridClassName}>
                <FormField
                    className={fieldClassName}
                    labelText={t('label.summary.form.name.label')}
                    id={'name'}
                    hintText={errors.name && touched.name ? errors.name : null}
                    errorStyle={!!(errors.name && touched.name)}
                >
                    <FormInput
                        id={'name'}
                        name={'name'}
                        placeholder={t('label.summary.form.name.placeholder')}
                        errorStyle={!!(errors.name && touched.name)}
                    />
                </FormField>
                <FormField
                    className={fieldClassName}
                    labelText={t('label.summary.form.surname.label')}
                    id={'surname'}
                    hintText={errors.surname && touched.surname ? errors.surname : null}
                    errorStyle={!!(errors.surname && touched.surname)}
                >
                    <FormInput
                        id={'surname'}
                        name={'surname'}
                        placeholder={t('label.summary.form.surname.placeholder')}
                        errorStyle={!!(errors.surname && touched.surname)}
                    />
                </FormField>
                <FormField
                    className={fieldClassName}
                    labelText={t('label.summary.form.email.label')}
                    id={'email'}
                    hintText={errors.email && touched.email ? errors.email : null}
                    errorStyle={!!(errors.email && touched.email)}
                >
                    <FormInput
                        id={'email'}
                        name={'email'}
                        placeholder={t('label.summary.form.email.placeholder')}
                        errorStyle={!!(errors.email && touched.email)}
                    />
                </FormField>
                <FormField
                    className={`${fieldClassName} ${smallFieldClassName}`}
                    labelText={t('label.summary.form.phone.label')}
                    id={'phone'}
                    hintText={errors.phone && touched.phone ? errors.phone : null}
                    errorStyle={!!(errors.phone && touched.phone)}
                >
                    <FormInput
                        id={'phone'}
                        name={'phone'}
                        placeholder={t('label.summary.form.phone.placeholder')}
                        errorStyle={!!(errors.phone && touched.phone)}
                    />
                </FormField>
                <FormField
                    className={`${fieldClassName} ${smallFieldClassName}`}
                    labelText={`${eventLabelText}`}
                    id={'weddingDate'}
                    hintText={errors.weddingDate && touched.weddingDate ? errors.weddingDate : null}
                    errorStyle={!!(errors.weddingDate && touched.weddingDate)}
                >
                    <FormDatePicker
                        id={'weddingDate'}
                        errorStyle={!!(errors.weddingDate && touched.weddingDate)}
                        format={'YMD'}
                        initValue={initDate}
                        minDate={minDate}
                    />
                </FormField>
            </div>
        </div>
    );
};

export default SummaryFormDataSection;
