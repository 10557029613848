import { ILabelSummaryFormValues } from '../models/summary-form.model';
import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { object, string } from 'yup';

export const initialLabelSummaryForm: ILabelSummaryFormValues = {
    receiptImages: null,
    store: '',
    name: '',
    surname: '',
    email: '',
    phone: '',
    weddingDate: '',
    shipmentName: '',
    shipmentSurname: '',
    shipmentCity: '',
    shipmentPostcode: '',
    shipmentStreet: '',
    shipmentBuildingNumber: '',
    shipmentLocalNumber: '',
    rules1: false,
    rules2: false,
};

export function getLabelSummaryFormSchema(t: TFunction<string | string[]>) {
    return Yup.object().shape({
        receiptImages: Yup.array()
            .of(object({ content: string(), mimeType: string(), name: string() }))
            .nullable()
            .required(t('form.required.error')),
        store: Yup.string().required(t('form.required.error')),
        name: Yup.string().required(t('form.required.error')),
        surname: Yup.string().required(t('form.required.error')),
        email: Yup.string()
            .email(t('label.summary.form.email.error'))
            .required(t('form.required.error')),
        phone: Yup.string().required(t('form.required.error')),
        weddingDate: Yup.string().required(t('form.required.error')),
        shipmentName: Yup.string().required(t('form.required.error')),
        shipmentSurname: Yup.string().required(t('form.required.error')),
        shipmentCity: Yup.string().required(t('form.required.error')),
        shipmentPostcode: Yup.string().required(t('form.required.error')),
        shipmentStreet: Yup.string().required(t('form.required.error')),
        shipmentBuildingNumber: Yup.string().required(t('form.required.error')),
        shipmentLocalNumber: Yup.string(),
        rules1: Yup.boolean().oneOf([true], t('form.required.error')),
        rules2: Yup.boolean().oneOf([true], t('form.required.error')),
    });
}
