import React, { useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { navigate } from 'gatsby';
import { useMutation } from 'react-query';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';

import {
    form,
    section,
    sectionLabel,
    sectionGrid,
    formField,
    formFieldSmall,
    checkboxesWrapper,
    buttonWrapper,
} from './label-summary-form.module.scss';

import { ICreateLabelValues } from '../../models/create-form.model';

import { getLabelSummaryFormSchema } from '../../formik/label-summary-form.config';
import { initialLabelSummaryForm } from '../../formik/label-summary-form.config';
import pages from '../../config/pages-context';
import { postLabelCreate } from '../../api-ssr/label-styles';
import { formatStringToDate } from '../../utils/format-date';

import FormCheckbox from '../atoms/form-checkbox';
import Button from '../atoms/button';
import SummaryFormReceiptSection from '../molecules/summary-form-receipt-section';
import SummaryFormDataSection from '../molecules/summary-form-data-section';
import SummaryFormDeliverySection from '../molecules/summary-form-delivery-section';

interface ILabelSummaryForm {
    labels: ICreateLabelValues[];
    generatorUrl: string;
    occasion: string;
    regulationsUrl: string;
}

const LabelSummaryForm: React.FC<ILabelSummaryForm> = ({ labels, generatorUrl, occasion, regulationsUrl }) => {
    const { t, language } = useI18next();
    const [buttonLoading, setButtonLoading] = useState(false);

    const summaryFormSchema = useMemo(() => getLabelSummaryFormSchema(t), [t]);

    const mutation = useMutation(postLabelCreate, {
        onSuccess: (_data, variables, _context) => {
            window.sessionStorage.setItem('sendLabels', JSON.stringify(variables.labels));
            window.localStorage.removeItem('savedLabels');
            navigate(getPathFromSlug(pages.labelThankYou.slug, language));
            setButtonLoading(false);
        },
        onError: () => {
            setButtonLoading(false);
            alert(t('label.summary.form.api.error'));
        },
    });

    const date = labels.length ? labels[0]?.date : null;
    const dateMemo = useMemo(() => formatStringToDate(date), [date]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialLabelSummaryForm}
            validationSchema={summaryFormSchema}
            validateOnMount={true}
            onSubmit={(values, actions) => {
                setButtonLoading(true);
                actions.setSubmitting(false);
                mutation.mutate({ ...values, labels });
            }}
        >
            {({ errors, touched }) => (
                <Form className={form}>
                    <SummaryFormReceiptSection
                        wrapperClassName={section}
                        labelClassName={sectionLabel}
                        gridClassName={sectionGrid}
                        fieldClassName={formField}
                    />
                    <SummaryFormDataSection
                        wrapperClassName={section}
                        labelClassName={sectionLabel}
                        gridClassName={sectionGrid}
                        fieldClassName={formField}
                        smallFieldClassName={formFieldSmall}
                        initDate={dateMemo}
                        occasion={occasion}
                    />
                    <SummaryFormDeliverySection
                        wrapperClassName={section}
                        labelClassName={sectionLabel}
                        gridClassName={sectionGrid}
                        fieldClassName={formField}
                        smallFieldClassName={formFieldSmall}
                    />
                    <div className={checkboxesWrapper}>
                        <FormCheckbox
                            name={'rules1'}
                            text={t('label.summary.form.rules.1.label')}
                            textContinued={t('label.summary.form.rules.1.label.continued')}
                            textLink={t('label.summary.form.rules.1.label.link')}
                            link={regulationsUrl}
                            hintText={errors.rules1 && touched.rules1 ? errors.rules1 : null}
                            errorStyle={!!(errors.rules1 && touched.rules1)}
                        />
                        <FormCheckbox
                            name={'rules2'}
                            text={t('label.summary.form.rules.2.label')}
                            hintText={errors.rules2 && touched.rules2 ? errors.rules2 : null}
                            errorStyle={!!(errors.rules2 && touched.rules2)}
                        />
                    </div>
                    <div className={buttonWrapper}>
                        <Button variant={'text'} as={'link'} to={`${generatorUrl}`}>
                            {t('label.summary.form.button.back')}
                        </Button>
                        <Button type={'submit'} isLoading={buttonLoading}>
                            {t('label.summary.form.button.submit')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default LabelSummaryForm;
