// extracted by mini-css-extract-plugin
export var backgroundAnimation = "label-summary-form-module--backgroundAnimation--a38d3";
export var buttonWrapper = "label-summary-form-module--button-wrapper--a75c0";
export var checkboxesWrapper = "label-summary-form-module--checkboxes-wrapper--2aab8";
export var errorBlink = "label-summary-form-module--error-blink--f59dc";
export var form = "label-summary-form-module--form--2e60b";
export var formField = "label-summary-form-module--form-field--7d756";
export var formFieldSmall = "label-summary-form-module--form-field-small--a119a";
export var section = "label-summary-form-module--section--bed32";
export var sectionGrid = "label-summary-form-module--section-grid--0f7b1";
export var sectionLabel = "label-summary-form-module--section-label--85cbb";