import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useFormikContext } from 'formik';

import { ISummaryFormSection } from '../../models/summary-form-section';
import { ILabelSummaryFormValues } from '../../models/summary-form.model';

import FormField from '../atoms/form-field';
import FileInput from '../atoms/file-input';
import FormInput from "../atoms/form-input";

const SummaryFormReceiptSection: React.FC<ISummaryFormSection> = ({
    wrapperClassName,
    gridClassName,
    labelClassName,
    fieldClassName,
}) => {
    const { t } = useI18next();
    const { errors, touched } = useFormikContext<ILabelSummaryFormValues>();

    return (
        <div className={wrapperClassName}>
            <span className={labelClassName}>{t('label.summary.form.receipt.section')}</span>
            <div className={gridClassName}>
                <FormField
                    className={fieldClassName}
                    labelText={t('label.summary.form.receipt.image.label')}
                    id={'receiptImages'}
                    hintText={
                        errors.receiptImages && touched.receiptImages
                            ? errors.receiptImages
                            : t('label.summary.form.receipt.image.hint')
                    }
                    errorStyle={!!(errors.receiptImages && touched.receiptImages)}
                >
                    <FileInput
                        name="receiptImages"
                        placeholder={t('label.summary.form.receipt.image.placeholder')}
                        maxFileSize={maxFileSizeBytes}
                        maxFileCount={10}
                        fileTypes={['image/jpeg', 'image/png', 'application/pdf']}
                        errorStyle={!!(errors.receiptImages && touched.receiptImages)}
                    />
                </FormField>
                <FormField
                    className={fieldClassName}
                    labelText={t('label.summary.form.store.label')}
                    id={'store'}
                    hintText={errors.store && touched.store ? errors.store : null}
                    errorStyle={!!(errors.store && touched.store)}
                >
                    <FormInput
                        id={'store'}
                        name={'store'}
                        placeholder={t('label.summary.form.store.placeholder')}
                        errorStyle={!!(errors.store && touched.store)}
                    />
                </FormField>
            </div>
        </div>
    );
};

const maxFileSizeBytes = 8388608;

export default SummaryFormReceiptSection;
