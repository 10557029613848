import React from 'react';
import { graphql, navigate } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getPathFromSlug } from '../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';
import { QueryClient, QueryClientProvider } from 'react-query';

import { container, layout, header, savedLabel } from './label-summary-page.module.scss';

import { IQueryAllResult } from '../models/query-all-result.model';
import { ILabelStyleCreator } from '../models/label-style.model';
import { TCommonTemplateData } from '../models/common-template-data.model';

import { getNodes } from '../utils/get-nodes';
import { useSavedLabels } from '../hooks/use-saved-labels';
import { isBrowser } from '../utils/is-browser';

import staticFiles from '../config/static-files';

import MainLayout from '../layouts/main-layout';
import Title from '../components/atoms/title';
import SavedLabels from '../components/organisms/saved-labels';
import FormLabel from '../components/atoms/form-label';
import LabelSummaryForm from '../components/organisms/label-summary-form';

const queryClient = new QueryClient();

interface ILabelSummaryPage {
    readonly data: {
        allStrapiLabelStyle: IQueryAllResult<ILabelStyleCreator>;
    } & TCommonTemplateData;
}

const LabelSummaryPage: React.FC<ILabelSummaryPage> = ({ data }) => {
    const labelStyles = getNodes(data.allStrapiLabelStyle);
    const { t, language } = useI18next();
    const { savedLabels, removeSavedLabel } = useSavedLabels();

    let generatorUrl = '';

    switch (labelStyles[0].occasion) {
        case 'wedding':
            generatorUrl = '/soplica-weselna/generator'
            break;
        case 'birthday':
            generatorUrl = '/soplica-urodzinowa/generator'
            break;
        case 'anniversary':
            generatorUrl = '/soplica-rocznicowa/generator'
            break;
    }

    if (!savedLabels.length) {
        if (isBrowser()) {
            navigate(getPathFromSlug(`${generatorUrl}/`, language));
        }
    }

    const editSavedLabel = (index: number) => {
        navigate(`${getPathFromSlug(`${generatorUrl}/`, language)}?edit=${index}`);
    };

    const handleRemoveSavedLabel = async (index: number) => {
        await removeSavedLabel(index);
    };

    return (
        <MainLayout className={layout} showRecipesSlider={false} includeHeaderPadding={true}>
            <div className={container}>
                <Title className={header} Tag="h1">
                    {t('label.summary.page.title')}
                </Title>
                <FormLabel className={savedLabel} labelText={t('label.summary.page.saved.label')} />
                <SavedLabels
                    labels={savedLabels}
                    onRemoveSavedLabel={handleRemoveSavedLabel}
                    onEditSavedLabel={editSavedLabel}
                    labelStyles={labelStyles}
                />
                <QueryClientProvider client={queryClient}>
                    <LabelSummaryForm labels={savedLabels} generatorUrl={generatorUrl} regulationsUrl={staticFiles.labelRegulations} occasion={labelStyles[0].occasion} />
                </QueryClientProvider>
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!, $occasion: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allStrapiLabelStyle(filter: { occasion: { eq: $occasion }, locale: { eq: $language } }) {
            edges {
                node {
                    type
                    occasion
                    ...labelStyleBottleImageField
                    ...labelStyleSampleImageField
                    ...labelStyleCleanImageField
                    ...labelSmallStyleBottleImageField
                    ...labelSmallStyleSampleImageField
                    ...labelSmallStyleCleanImageField
                    ...labelLemonStyleBottleImageField
                    ...labelLemonStyleSampleImageField
                    ...labelLemonStyleCleanImageField
                    ...labelImageDigits
                    ...labelStyleTextStyles
                    ...labelSmallStyleTextStyles
                    ...labelLemonStyleTextStyles
                    capacities {
                        capacity
                    }
                    smallCapacities {
                        capacity
                    }
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default LabelSummaryPage;
